import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import {graphql} from "gatsby";
import ExternalContent from "../components/ExternalContent";
import PageHeader from "../components/PageHeader";
import {Col, Row} from "antd";
import {MyTripContext} from "../context/MyTripContext";
import {trackLoadItinerary} from "../services/gtm";
import language from "../../data/language.yaml";

class ItinerariesPost extends Component {
    state = {
        itineraryItems: '',
        path: ''
    };

    // constructor(props){
    //     super(props);
    // }

    componentDidMount() {
        if(this.props.data.wordpressWpItinerary) {
            this.setState({
                itineraryItems: this.props.data.wordpressWpItinerary.acf.days
            });
        }
    }

    loadItinerary = () => {
        // Deep copy the array because subsequent clicks won't work as the array is cleared in context
        let days = JSON.parse(JSON.stringify(this.state.itineraryItems));
        this.context.loadPremadeItinerary(days);
        // Track this load
        trackLoadItinerary();
    };

    render() {
        let modules = buildPostModules(this.props.data.wordpressWpItinerary.acf.content_itinerary);

        const post = this.props.data.wordpressWpItinerary;
        const acfFields = this.props.data.wordpressWpItinerary.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = post.title;
        }

        let path = this.props.data.wordpressWpItinerary.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile?.childImageSharp?.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile?.childImageSharp?.original.src : null}
                path={`/${path}`}
                locale={post.translations.current}
                translations={post.translations}
            >
                <PageHeader
                    title = {post.title}
                    videoID = ""
                    image = {post.acf.image && post.acf.image.localFile?.childImageSharp?.fluid}
                    slides = ""
                    headerColor = "primary"
                    // options: primary, secondary, orange, teal, yellow
                    headerStyle = "cutout"
                    // options: landing, cutout, cutout-small, full
                    back = "true"
                    date = ""
                />
                <div className="blog-post">
                    <Row gutter={70}>
                        <Col xs={{span: 24}}>
                            <a onClick={this.loadItinerary} className={'btn btn-green add-to-trip'} href="##"><span>{language.loadItin[post.translations.current]}</span><span className="icon arrow" /></a>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 20, offset: 2}} lg={{span: 16, offset: 4}}>
                            {modules.map((module) => {
                                return module;
                            })}
                        </Col>
                    </Row>
                </div>
            </Wrapper>
        )
    }
}



function buildPostModules (modules){
    let response = [];
    for (let module in modules){

        const moduleType = modules[module].__typename;
        let content = '';

        switch (moduleType){

            case 'WordPressAcf_full_text':
                content = <ExternalContent key={modules[module].id}><div dangerouslySetInnerHTML={{__html: modules[module].text }} /></ExternalContent>
                break;

            case 'WordPressAcf_image_text':
                if (modules[module].image) {
                    let image = null;
                    const mimeType = modules[module].image.mime_type;
                    if (mimeType === 'image/gif' || mimeType === 'image/svg') {
                        image = modules[module].image.localFile?.publicURL;
                    } else {
                        image = modules[module].image.localFile?.childImageSharp?.original?.src;
                    }
                    content = <ExternalContent key={modules[module].id}>
                        <img className={modules[module].image_side}
                             src={image} alt=""/>
                        <div dangerouslySetInnerHTML={{__html: modules[module].text}}/>
                    </ExternalContent>
                }
                break;

            default:
                // Unknown module
                break;
        }
        if (content) response.push(content);
    }
    return response;
}

export const itinQuery = graphql`
  query QueryItinPostDetail($id: String!) {
    wordpressWpItinerary(id: { eq: $id }) {
      id
      slug
      title
      translations {
        current
        en
        cy
      }
      acf {
        days {
          locations
        }
        image {
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
                fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                    ... GatsbyImageSharpFluid
                }
            }
          }    
        }
        excerpt
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
		content_itinerary {
          __typename
          ...on WordPressAcf_full_text {
            id
            text
          }
          ...on WordPressAcf_image_text {
            id
            text
            image_side
            image {
              mime_type
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxHeight: 450, quality: 80) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

ItinerariesPost.contextType = MyTripContext;

export default ItinerariesPost
